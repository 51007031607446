import Axios from 'axios';
import moment from 'moment';

export const NoticiasServices = {
    api: {
        async getCartelera() {
            return Axios.get('cartelera')
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('noticias')
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async save(noticia) {
            return Axios.post('noticias', noticia, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async put(id, noticia) {
            noticia.append('_method', 'PUT');
            return Axios.post('noticias/' + id, noticia, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async find(id) {
            return Axios.get('noticias/' + id)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async findByUuid(uuid) {
            return Axios.get('noticias/uuid/' + uuid)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async delete(licencia) {
            return Axios.delete('noticias/' + licencia.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },

    headers() {
        const header = [
            { value: 'Título', sortable: true },
            { value: 'Tipo', sortable: true },
            { value: 'Desde', sortable: true },
            { value: 'Hasta', sortable: true },
            { value: 'Publicado', sortable: true },
        ]
        return header;
    },
    body() {
        const body = [
            {
                class: 'w-100 important text-bold',
                data: [{ value: 'titulo' }]
            },
            {
                class: 'w-100 important',
                data: [{
                    value: 'tipo', label: "Tipo", mutator: (item) => {
                        if (item === 'remember') { return 'Recordatorio' }
                        if (item === 'felicitaciones') { return 'Felicitación' }
                        if (item === 'luto') { return 'Luto' }
                        if (item === 'novedad') { return 'Novedad' }
                        if (item === 'interes') { return 'Información de interés' }
                        if (item === 'procedimiento') { return 'Procedimiento' }
                    }
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'desde', label: "Desde",
                    mutator: this.normalDate
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'hasta', label: "Hasta",
                    mutator: this.normalDate
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'publicado', label: "Publicado",
                    mutator: (item)=> {
                        if(item){
                            return 'Si';
                        }
                        return 'No';
                    }
                }]
            }
        ]
        return body
    },
    normalDate(value) {
        let date = moment(value)
        return date.format('DD/MM/YYYY');
    },
}